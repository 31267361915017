import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box } from '@material-ui/core';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box style={{ color:'#fff'}} align="center" mt={5}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Box>
  </Layout>
)

export default NotFoundPage
